import React, { useEffect, useState } from 'react';
import addToMailchimp from "gatsby-plugin-mailchimp";

import useCountriesData from "../../hooks/hook.useCountriesData";
import useFetchPost from "../../hooks/hook.useFetchPost";
import Button from '../component.button';


const DemoForm = () => {
    const countries = useCountriesData();
    const [formSubmitted, setFormSubmitted] = useState(false);

    const defaultContactDetails = {
        interestedBoat: '',
        country: '',
        phoneNumber: '',
        contactName: '',
        club: '',
        email: '',
        weight: '',
        purchaseType: '',
        howHearOption: '',
        howHear: '',
        howHearOther: '',
        newsletter: false,
    }
    const [contactDetails, setContactDetails] = useState(defaultContactDetails);

    const handleInputChange = event => {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        // handle checkbox
        if (name === 'newsletter') {
            value = target.checked;
        }

        setContactDetails({
            ...contactDetails,
            [name]: value,
        });
    };

    let interestedBoatOptions = [
        'Racing Single',
        'Racing Pair/Quad',
        'Racing Four/Quad',
        'Racing Eight/Octuple',
        'Recreational/Learn To Row Single',
        'Recreational/Learn To Row Pair/Double',
        'Recreational/Learn To Row Four/Quad',
        'Recreational/Learn To Row SUP Rower',
        'Adaptive Single',
        'Adaptive Pair/Double',
        'Adaptive Four/Quad',
        'Coastal Single',
        'Coastal Double',
        'Coastal Quad',
        'Coaching Launch'
    ];

    if (process.env.GATSBY_THEME == "King") {
        interestedBoatOptions = [
        'King 4+',
        'King 8+',
        'Both'
        ];
    }
    
    const purchaseTypeOptions = [
        'Private',
        'Club',
        'School',
        'University',
        'National Team',
        'Other'
    ]
    
    const howHearOptions = [
        'Currently row WinTech',
        'Other club',
        'Regatta',
        'Facebook',
        'Instagram',
        'Twitter',
        'Google',
        'YouTube',
        'World Rowing',
        'Row360',
        'Row2k',
        'Rowing News',
        'Other (Please Specify)'
    ]

    const formSubmission = useFetchPost({
        path: '/wp-json/contact-form-7/v1/contact-forms/1919/feedback',
        data: contactDetails,
        submitClick: formSubmitted
    });

    const handleSubmit = async event => {
        event.preventDefault();

        if (contactDetails.howHearOption === 'Other (Please Specify)') {
            contactDetails.howHear = contactDetails.howHearOther;
        } else {
            contactDetails.howHear = contactDetails.howHearOption;
        }
        
        // add the selected class/hull button selections
        setContactDetails({
            ...contactDetails,
        });
        
        if (contactDetails.newsletter) {
            addToMailchimp(contactDetails.email, { 'PAGEID': 'Demo' });
        }
        

        // submit the form
        await setFormSubmitted(true);
        // revert the form submitted to default state;
        await setFormSubmitted(false);
    };

    useEffect(() => {
        // clear the form if it's successful
        if (formSubmission[0].success === true) {
            formSubmission[0].success = false;
            setContactDetails(defaultContactDetails);
        }
    }, [formSubmitted, formSubmission]);

    return (
        <div className="container xs-pb-60">
            <div className="row">
                <div className="col-12">
                    <div className="c-form c-contact-form">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
    
                                <div className="c-contact-form__header">
                                    <h2>Ready To Try A Boat?</h2>
                                </div>
                                
                                <form className="c-form" onSubmit={handleSubmit}>
    
                                    <div className="row">
                                        
                                        <div className="col-sm-12">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="interestedBoat"
                                                        name="interestedBoat"
                                                        value={contactDetails.interestedBoat}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>WHAT BOAT ARE YOU INTERESTED IN TRYING?</option>
                                                        {interestedBoatOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
         
                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={contactDetails.contactName.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="contactName"
                                                    name="contactName"
                                                    value={contactDetails.contactName}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="c-input-holder__label" htmlFor="contactName">Name</label>
                                            </div>
                                        </div>
    
                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={contactDetails.email.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="contactEmail"
                                                    name="email"
                                                    value={contactDetails.email}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="c-input-holder__label" htmlFor="contactEmail">Email</label>
                                            </div>
                                        </div>
    
                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={contactDetails.phoneNumber.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="number"
                                                    id="contactPhoneNumber"
                                                    name="phoneNumber"
                                                    value={contactDetails.phoneNumber}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="c-input-holder__label" htmlFor="contactPhoneNumber">Phone number</label>
                                            </div>
                                        </div>
                                        
                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="contactCountry"
                                                        name="country"
                                                        value={contactDetails.country}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>COUNTRY</option>
                                                        {countries.map((country, index) => (
                                                            <option key={index} value={country.ACFDealerBlockFields.country}>{country.ACFDealerBlockFields.country}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={contactDetails.club.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="club"
                                                    name="club"
                                                    value={contactDetails.club}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="c-input-holder__label" htmlFor="club">Affiliated Club <span>(optional)</span></label>
                                            </div>
                                        </div>
    
                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={contactDetails.weight.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="weight"
                                                    name="weight"
                                                    value={contactDetails.weight}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="c-input-holder__label" htmlFor="weight">Athlete Weight/Average Crew Weight <span>(optional)</span></label>
                                            </div>
                                        </div>
    
                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="purchaseType"
                                                        name="purchaseType"
                                                        value={contactDetails.purchaseType}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>PURCHASE TYPE</option>
                                                        {purchaseTypeOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="howHearOption"
                                                        name="howHearOption"
                                                        value={contactDetails.howHearOption}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>HOW DID YOU HEAR ABOUT US</option>
                                                        {howHearOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
    
                                            <div className={contactDetails.howHearOption !== 'Other (Please Specify)' ?  `c-input-holder c-input-holder--hidden` : 'c-input-holder'}>
                                                <input
                                                    className={contactDetails.howHearOther.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="howHearOther"
                                                    name="howHearOther"
                                                    value={contactDetails.howHearOther}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        
                                    </div>
                    
                                    <div className="c-contact-form__footer">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="c-input-holder__checkbox-wrapper xs-mb-20 md-mb-0">
                                                    <div>
                                                        <input
                                                            className="c-input-holder__checkbox"
                                                            type="checkbox"
                                                            name="newsletter"
                                                            id="newsletter"
                                                            checked={contactDetails.newsletter}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <label className="c-input-holder__label--checkbox" htmlFor="newsletter">Tick here to receive the latest product updates and {(process.env.GATSBY_THEME == "King" ? "KING" : "WINTECH")} racing news straight into your inbox</label>
                                                </div>
                                            </div>
                            
                                            <div className="col-md-6">
                                                <div className="c-contact-form__submit">
                                                    <Button text="Send Enquiry" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DemoForm;
