import React, { useEffect, useState } from 'react';
import addToMailchimp from "gatsby-plugin-mailchimp";

import useCountriesData from "../../hooks/hook.useCountriesData";
import useFetchPost from "../../hooks/hook.useFetchPost";
import Button from '../component.button';


const CoastalBoatForm = () => {
    const countries = useCountriesData();
    const [formSubmitted, setFormSubmitted] = useState(false);

    const defaultFormDetails = {
        contactName: '',
        email: '',
        phoneNumber: '',
        country: '',
        club: '',
        weight: '',
        howHearOption: '',
        howHear: '',
        howHearOther: '',
        request: '',
        purchase: '',
        newsletter: false
    }
    const [formDetails, setFormDetails] = useState(defaultFormDetails);

    const handleInputChange = event => {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        // handle checkbox
        if (name === 'newsletter') {
            value = target.checked;
        }

        setFormDetails({
            ...formDetails,
            [name]: value,
        });
    };

    const howHearOptions = [
        'Currently row WinTech',
        'Other club',
        'Regatta',
        'Facebook',
        'Instagram',
        'Twitter',
        'Google',
        'YouTube',
        'RowingBlazers.com',
        'World Rowing',
        'Row360',
        'Row2k',
        'Rowing News',
        'Other (Please Specify)'
    ];

    const requestOptions = [
        'Pricing & Availability',
        'A Boat trial, pricing & availability',
        'A call to discuss my requirements'
    ];

    const purchaseOptions = [
        'ASAP',
        'Within 1 month',
        '2-3 months',
        '4-6 months',
        '6-12 months',
        'More than 12 months',
        'Unsure'
    ];

    const interestOptions = [
        '1x',
        '2x',
        '4x+',
        'Multiple Boats'
    ];

    const formSubmission = useFetchPost({
        path: '/wp-json/contact-form-7/v1/contact-forms/2978/feedback',
        data: formDetails,
        submitClick: formSubmitted
    });

    const handleSubmit = async event => {
        event.preventDefault();

        if (formDetails.howHearOption === 'Other (Please Specify)') {
            formDetails.howHear = formDetails.howHearOther;
        } else {
            formDetails.howHear = formDetails.howHearOption;
        }

        formDetails.request = formDetails.requestOption;
        formDetails.purchase = formDetails.purchaseOption;
        formDetails.interest = formDetails.interestOption;

        // add the selected class/hull button selections
        setFormDetails({
            ...formDetails,
        });

        if (formDetails.newsletter) {
            addToMailchimp(formDetails.email, { 'PAGEID': 'CoastalBoat' });
        }

        // submit the form
        await setFormSubmitted(true);
        // revert the form submitted to default state;
        await setFormSubmitted(false);
    };

    useEffect(() => {
        // clear the form if it's successful
        if (formSubmission[0].success === true) {
            formSubmission[0].success = false;
            setFormDetails(defaultFormDetails);
        }
    }, [formSubmitted, formSubmission]);

    return (
        <div className="container xs-pb-60">
            <div className="row">
                <div className="col-12">
                    <div className="c-form c-contact-form">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">

                                <div className="c-contact-form__header">
                                    <h2 id="enquire">ENQUIRE NOW</h2>
                                </div>

                                <form className="c-form" onSubmit={handleSubmit}>

                                    <div className="row">

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={formDetails.contactName.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="contactName"
                                                    name="contactName"
                                                    value={formDetails.contactName}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="c-input-holder__label" htmlFor="contactName">Name</label>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={formDetails.email.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="contactEmail"
                                                    name="email"
                                                    value={formDetails.email}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="c-input-holder__label" htmlFor="contactEmail">Email</label>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={formDetails.phoneNumber.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="number"
                                                    id="contactPhoneNumber"
                                                    name="phoneNumber"
                                                    value={formDetails.phoneNumber}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="c-input-holder__label" htmlFor="contactPhoneNumber">Phone number</label>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="contactCountry"
                                                        name="country"
                                                        value={formDetails.country}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>COUNTRY</option>
                                                        {countries.map((country, index) => (
                                                            <option key={index} value={country.ACFDealerBlockFields.country}>{country.ACFDealerBlockFields.country}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={formDetails.club.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="club"
                                                    name="club"
                                                    value={formDetails.club}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="c-input-holder__label" htmlFor="club">Affiliated Club <span>(optional)</span></label>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <input
                                                    className={formDetails.weight.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="weight"
                                                    name="weight"
                                                    value={formDetails.weight}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="c-input-holder__label" htmlFor="weight">Athlete Weight/Average Crew Weight <span>(optional)</span></label>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="interestOption"
                                                        name="interestOption"
                                                        value={formDetails.interestOption}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>I AM INTERESTED IN</option>
                                                        {interestOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="requestOption"
                                                        name="requestOption"
                                                        value={formDetails.requestOption}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>I'D LIKE TO REQUEST</option>
                                                        {requestOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="purchaseOption"
                                                        name="purchaseOption"
                                                        value={formDetails.purchaseOption}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>I AM LOOKING TO PURCHASE IN</option>
                                                        {purchaseOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="howHearOption"
                                                        name="howHearOption"
                                                        value={formDetails.howHearOption}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>HOW DID YOU HEAR ABOUT US</option>
                                                        {howHearOptions.map((option, index) => (
                                                            <option key={index} value={option}>{option}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className={formDetails.howHearOption !== 'Other (Please Specify)' ? `c-input-holder c-input-holder--hidden` : 'c-input-holder'}>
                                                <input
                                                    className={formDetails.howHearOther.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="howHearOther"
                                                    name="howHearOther"
                                                    value={formDetails.howHearOther}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="c-contact-form__footer">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="c-input-holder__checkbox-wrapper xs-mb-20 md-mb-0">
                                                    <div>
                                                        <input
                                                            className="c-input-holder__checkbox"
                                                            type="checkbox"
                                                            name="newsletter"
                                                            id="newsletter"
                                                            checked={formDetails.newsletter}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <label className="c-input-holder__label--checkbox" htmlFor="newsletter">Tick here to receive the latest product updates and {(process.env.GATSBY_THEME == "King" ? "KING" : "WINTECH")} Racing news straight into your inbox</label>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="c-contact-form__submit">
                                                    <Button text="Send Enquiry" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoastalBoatForm;
