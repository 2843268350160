import React, { useEffect, useState } from 'react';
import addToMailchimp from "gatsby-plugin-mailchimp";
import { withTranslation } from 'react-i18next';

import useCountriesData from "../../hooks/hook.useCountriesData";
import useFetchPost from "../../hooks/hook.useFetchPost";
import Button from '../component.button';


const BoysBoatForm = () => {
    const countries = useCountriesData();
    const [formSubmitted, setFormSubmitted] = useState(false);

    const defaultFormDetails = {
        contactName: '',
        email: '',
        phoneNumber: '',
        customercountry: '',
        club: '',
        newsletter: false,
        onlinemeeting: false,
    }
    const [formDetails, setFormDetails] = useState(defaultFormDetails);

    const handleInputChange = event => {
        const target = event.target;
        let value = target.value;
        const name = target.name;

        // handle checkbox
        if (name === 'newsletter') {
            value = target.checked;
        }

        // handle checkbox
        if (name === 'onlinemeeting') {
            value = target.checked;
        }

        setFormDetails({
            ...formDetails,
            [name]: value,
        });
    };

    const howHearOptions = [
        'Currently row WinTech',
        'Other club',
        'Regatta',
        'Facebook',
        'Instagram',
        'Twitter',
        'Google',
        'YouTube',
        'RowingBlazers.com',
        'World Rowing',
        'Row360',
        'Row2k',
        'Rowing News',
        'Other (Please Specify)'
    ];

    const formSubmission = useFetchPost({
        path: '/wp-json/contact-form-7/v1/contact-forms/3902/feedback',
        data: formDetails,
        submitClick: formSubmitted
    });

    const handleSubmit = async event => {
        event.preventDefault();

        // add the selected class/hull button selections
        setFormDetails({
            ...formDetails,
        });

        if (formDetails.newsletter) {
            addToMailchimp(formDetails.email, { 'PAGEID': 'BoysInTheBoat' });
        }

        // submit the form
        await setFormSubmitted(true);
        // revert the form submitted to default state;
        await setFormSubmitted(false);
    };

    useEffect(() => {
        // clear the form if it's successful
        if (formSubmission[0].success === true) {
            formSubmission[0].success = false;
            setFormDetails(defaultFormDetails);
        }
    }, [formSubmitted, formSubmission]);


    return (
        <div className="container xs-pb-60">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="c-contact-form__header">
                        <h2>ENQUIRE NOW</h2>
                    </div>
                    <p>Interested in owning the limited edition boat and giving back to the next generation of Washington Athletes. Fill in your details below and a member of our team will be in touch once the boat becomes available.</p>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="c-form c-contact-form">
                        <div>
                            <div>

                                <form className="c-form" onSubmit={handleSubmit}>

                                    <div className="row">

                                        <div className="col-12">
                                            <div className="c-input-holder">
                                                <input
                                                    className={formDetails.contactName.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="contactName"
                                                    name="contactName"
                                                    value={formDetails.contactName}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="c-input-holder__label" htmlFor="contactName">Name</label>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="c-input-holder">
                                                <input
                                                    className={formDetails.email.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="contactEmail"
                                                    name="email"
                                                    value={formDetails.email}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="c-input-holder__label" htmlFor="contactEmail">Email</label>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="c-input-holder">
                                                <input
                                                    className={formDetails.phoneNumber.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="number"
                                                    id="contactPhoneNumber"
                                                    name="phoneNumber"
                                                    value={formDetails.phoneNumber}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <label className="c-input-holder__label" htmlFor="contactPhoneNumber">Phone number</label>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="c-input-holder">
                                                <input
                                                    className={formDetails.club.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                                    type="text"
                                                    id="club"
                                                    name="club"
                                                    value={formDetails.club}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="c-input-holder__label" htmlFor="club">Affiliated Club <span>(optional)</span></label>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="c-input-holder">
                                                <div className="c-input-holder__wrapper">
                                                    <select
                                                        className="c-input-holder__select"
                                                        id="contactCountry"
                                                        name="customercountry"
                                                        value={formDetails.customercountry}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value=''>COUNTRY</option>
                                                        {countries.map((country, index) => (
                                                            <option key={index} value={country.ACFDealerBlockFields.country}>{country.ACFDealerBlockFields.country}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="c-contact-form__footer">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                
                                                <div className="c-input-holder__checkbox-wrapper xs-mb-20 xs-mb-20">
                                                    <div>
                                                        <input
                                                            className="c-input-holder__checkbox"
                                                            type="checkbox"
                                                            name="onlinemeeting"
                                                            id="onlinemeeting"
                                                            value={formDetails.onlinemeeting}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <label className="c-input-holder__label--checkbox" htmlFor="onlinemeeting">Want to book an online meeting?</label>
                                                </div>
                                                <div className="c-input-holder__checkbox-wrapper xs-mb-20 xs-mb-20">
                                                    <div>
                                                        <input
                                                            className="c-input-holder__checkbox"
                                                            type="checkbox"
                                                            name="newsletter"
                                                            id="newsletter"
                                                            value={formDetails.newsletter}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <label className="c-input-holder__label--checkbox" htmlFor="newsletter">Sign up for our newsletter</label>
                                                </div>

                                            
                                                <div className="c-contact-form__submit">
                                                    <Button text="Send enquiry" />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BoysBoatForm;