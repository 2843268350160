import React, { useEffect, useState, useRef, useContext } from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import Modal from 'react-modal';

import ThemeContext from "../context/context.theme";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from '../components/component.button';

// Bind modal to the appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby');

/**
 * Slider with video/image options and caption
 * When using a video hides until video is loaded to stop FOUC
 *
 * @param {*} { slides }
 * @returns
 */
const Hero = ({ slides }) => {
    const [loaded, setLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const video = useRef(null);
    const theme = useContext(ThemeContext);
    
    useEffect(() => {
        if (video && video.current) {
            // Add callback when video is loaded if the video isn't already loaded
            if (video.current.readyState === 0) {
                video.current.addEventListener('loadeddata', () => {
                    setLoaded(true);
                });
            } else {
                setLoaded(true);
            }
        } else {
            setLoaded(true);
        }
    }, []);

    const sliderSettings = {
        dots: true,
        infinite: true,
        centerMode: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    centerMode: false,
                }
            },
        ],
    };

    return (
        <>
            <Slider className={loaded ? "c-hero c-hero--loaded" : "c-hero"} {...sliderSettings}>
                {slides.map(slide => {
                    const hasVideo = (slide.videoMp4 || slide.videoWebm) ? theme.allowVideo : false;
                    
                    return (
                        <>
                        <div className={hasVideo ? 'c-hero__item c-hero__item--video' : 'c-hero__item'}>
                            {hasVideo && (
                                <div className="c-hero__video-container">
                                    <video ref={video} className="c-hero__video"
                                           poster={slide.image ? slide.image.imageFile.childImageSharp.fluid.src : ''}
                                           muted="muted" playsinline="playsinline" autoPlay loop>
                                        {slide.videoMp4 && (
                                            <source src={slide.videoMp4.mediaItemUrl} type="video/mp4"/>)}
                                        {slide.videoWebm && (
                                            <source src={slide.videoWebm.mediaItemUrl} type="video/webm"/>)}
                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                </div>
                            )}
                            {slide.image && !hasVideo && (
                                <Img className="c-hero__image" fluid={slide.image.imageFile.childImageSharp.fluid} alt={slide.image.altText} />
                            )}
                            {slide.mobileImage && !hasVideo &&  (
                                <Img className="c-hero__image c-hero__image--mobile" fluid={slide.mobileImage.imageFile.childImageSharp.fluid} alt={slide.mobileImage.altText} />
                            )}
                            <div className="c-hero__caption">
                                <h1 className="c-hero__title">{slide.title}</h1>
                                <span className="c-hero__subtitle">{slide.subTitle}</span>
                                <Button link to={slide.ctaLink.url} text={slide.ctaLabel}/>

                                {slide.fullVideo &&
                                    <div>
                                        <button
                                            onClick={() => setModalIsOpen(true)}
                                            className="c-hero__video-link"
                                        >
                                            <i className="far fa-play-circle"></i> Watch video
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        
                        {slide.fullVideo &&
                            <Modal
                                className={theme.loading ? 'c-modal c-modal--no-frame c-modal--loading' : 'c-modal c-modal--no-frame'}
                                overlayClassName="c-modal__overlay"
                                isOpen={modalIsOpen}
                                onRequestClose={() => setModalIsOpen(false)}
                            >
                                <iframe
                                    title="Video from YouTube"
                                    width="560"
                                    height="315"
                                    src={`https://www.youtube-nocookie.com/embed/${slide.fullVideo}?autoplay=1;rel=0;showinfo=0version=3;enablejsapi=1`}
                                    frameborder="0"
                                    showinfo="0"
                                    allowfullscreen
                                    modestbranding
                                    autoplay
                                >
                                </iframe>
                            </Modal>
                        }
                        </>
                    );
                })}
            </Slider>
        </>
    )
};

export default Hero;
