import React, { useEffect, useState, useRef, useContext } from 'react';
import Img from "gatsby-image";
import ThemeContext from "../context/context.theme";

const TwoImageFullWidth = ({ items }) => {

    const [loaded, setLoaded] = useState(false);
    const video = useRef(null);
    const theme = useContext(ThemeContext);
    const hasLeftVideo = (items.videoLeftMp4 || items.videoLeftWebm) ? theme.allowVideo : false;
    const hasRightVideo = (items.videoRightMp4 || items.videoRightWebm) ? theme.allowVideo : false;

    useEffect(() => {
        if (video && video.current) {
            // Add callback when video is loaded if the video isn't already loaded
            if (video.current.readyState === 0) {
                video.current.addEventListener('loadeddata', () => {
                    setLoaded(true);
                });
            } else {
                setLoaded(true);
            }
        } else {
            setLoaded(true);
        }
    }, []);

    return (
        <section className="c-full-width-images__two">
            {items.image_left && !hasLeftVideo && (
                <div className="c-full-width-images__image">
                    <Img key={items.image_left}
                         fluid={items.image_left.imageFile.childImageSharp.fluid}
                         alt={items.image_left.altText} />
                </div>
            )}
            {items.image_right && !hasLeftVideo && (
                <div className="c-full-width-images__image">
                    <Img key={items.image_right}
                         fluid={items.image_right.imageFile.childImageSharp.fluid}
                         alt={items.image_right.altText} />
                </div>
            )}
            {items.imageLeft && !hasRightVideo && (
                <div className="c-full-width-images__image">
                    <Img key={items.imageLeft}
                         fluid={items.imageLeft.imageFile.childImageSharp.fluid}
                         alt={items.imageLeft.altText} />
                </div>
            )}
            {items.imageRight && !hasRightVideo && (
                <div className="c-full-width-images__image">
                    <Img key={items.imageRight}
                         fluid={items.imageRight.imageFile.childImageSharp.fluid}
                         alt={items.imageRight.altText} />
                </div>
            )}


            {hasLeftVideo && (
                <video ref={video} className="c-hero__video"
                    poster={items.imageLeft ? items.imageLeft.imageFile.childImageSharp.fluid.src : ''}
                    controls playsinline="playsinline" autoPlay loop>
                    {items.videoLeftMp4 && (<source src={items.videoLeftMp4.mediaItemUrl} type="video/mp4"/>)}
                    {items.videoLeftWebm && (<source src={items.videoLeftWebm.mediaItemUrl} type="video/webm"/>)}
                    <Img key={items.imageLeft}
                    fluid={items.imageLeft.imageFile.childImageSharp.fluid}
                    alt={items.imageLeft.altText} />
                </video>
            )}

            {hasRightVideo && (
                <video ref={video} className="c-hero__video"
                    poster={items.imageRight ? items.imageRight.imageFile.childImageSharp.fluid.src : ''}
                    controls playsinline="playsinline" autoPlay loop>
                    {items.videoLeftMp4 && (<source src={items.videoLeftMp4.mediaItemUrl} type="video/mp4"/>)}
                    {items.videoLeftWebm && (<source src={items.videoLeftWebm.mediaItemUrl} type="video/webm"/>)}
                    <Img key={items.imageRight}
                    fluid={items.imageRight.imageFile.childImageSharp.fluid}
                    alt={items.imageRight.altText} />
                </video>
            )}



        </section>
    );
};

export default TwoImageFullWidth;