import React, { useContext } from 'react';
import { ParallaxLayer } from 'react-spring/renderprops-addons';
import { useTrail, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { Link } from 'gatsby';
import Img from "gatsby-image";

import PlaceholderImage from '../assets/images/categoriesRacing.jpg';
import ThemeContext from "../context/context.theme";

const speeds = [
    "0.01",
    "-0.050",
    "0.015",
    "-0.050"
];

const CategoriesComponent = ({ items: categories, parallax }) => {
    const theme = useContext(ThemeContext);

    const [ref, inView] = useInView({
        rootMargin: '-200px 0px',
        triggerOnce: true,
    });

    const imageTrail = useTrail(categories.length, {
        config: { tension: 280, friction: 60 },
        opacity: inView ? 1 : 0,
        transform: inView ? 'scale(1)' : 'scale(0)',
        from: { opacity: 0, transform: 'scale(0)' },
    });

    return (
        <div className={parallax ? "c-categories c-categories--parallax" : "c-categories"}>
            <ul ref={ref} className="c-categories__list">
                {imageTrail.map(({ ...rest }, index) => {
                    const { name, slug, acfFields: details } = categories[index];

                    return (
                        <li key={index} className={`c-categories__item c-categories__item--${index}`}>                    
                            {(parallax && theme.screenType === 'desktop') &&
                                <>
                                    {details.image && 
                                        <>
                                            <img className="u-hidden" src={PlaceholderImage} alt="Placeholder" />
                                            <ParallaxLayer key={index} className="c-categories__section" offset={0} speed={speeds[index]}>
                                                <animated.div style={rest}>
                                                    <Link to={slug} className="c-categories__link">
                                                        <Img className="c-categories__image" fluid={details.image.imageFile.childImageSharp.fluid} alt={details.image.altText ? details.image.altText : name} />
                                                        <div className="c-categories__details">
                                                            {(details.tag) && <span className="c-categories__details__tag">{details.tag}</span>}
                                                            <span>{name}</span>
                                                        </div>
                                                    </Link>
                                                </animated.div>
                                            </ParallaxLayer>
                                        </>
                                    }
                                </>
                            }
                            {(!parallax || theme.screenType !== 'desktop') &&
                                <>
                                    {details.image && 
                                        <animated.div key={index} className="c-categories__section" style={rest}>
                                            <Link to={slug} className="c-categories__link">
                                                <Img className="c-categories__image" fluid={details.image.imageFile.childImageSharp.fluid} alt={details.image.altText} />
                                                <div className="c-categories__details">
                                                    {(details.tag) && <span className="c-categories__details__tag">{details.tag}</span>}
                                                    <span>{name}</span>
                                                </div>
                                            </Link>
                                        </animated.div>
                                    }
                                </>
                            }
                        </li>
                    );
                })}
            </ul>
        </div>
    )
};

export default CategoriesComponent;
